import {
  Body,
  Card,
  CardLevel,
  Classes,
  Col,
  Divider,
  Drawer,
  Frow,
  Heading2,
  IResponsiveTabItem,
  Link,
  PaddingLevel,
  ResponsiveTabs,
  Subheading1,
  Subheading2,
} from '@pinpointhq/thumbtack';
import { format } from 'date-fns';
import { flatten } from 'lodash';
import * as React from 'react';
import { Address, Candidate, JobSeeker } from '../../../../javascript/models';
import { DrawerHeader } from '../../../shared/DrawerHeader';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import humanize from '../../../shared/utilities/humanize';
import { useSpraypaintNamedParams } from '../../shared/hooks/useSpraypaint';
import { AllocationTable } from './AllocationTable';

interface IShowDrawer {
  candidate: Candidate;
  handleClose: () => void;
  isOpen: boolean;
}

export function ShowDrawer({ candidate, handleClose, isOpen }: IShowDrawer) {
  const { data: jobSeekers, isLoading: isJobSeekersLoading, fetchData } = useSpraypaintNamedParams({
    initialScope: JobSeeker.where({ candidate_id: candidate.id })
      .order({ created_at: 'desc' })
      .includes(['addresses', { applications: { job: ['company'] } }])
      .selectExtra(['programme_cycle_name']),
  });

  const lastestJobSeeker = jobSeekers[0];

  const [selectedTab, setSelectedTab] = React.useState<string>('details');
  const handleTabChange = (tab: IResponsiveTabItem) => {
    setSelectedTab(tab.id as string);
  };

  React.useEffect(() => {
    if (selectedTab) {
      const element = document.getElementById(selectedTab);
      if (element) {
        element.scrollIntoView({ block: 'start', inline: 'nearest' });
      }
    }
  }, [selectedTab]);

  const tabs = [
    { text: 'Details', id: 'details' },
    { text: 'Allocations', id: 'allocations' },
    { text: 'Applications', id: 'applications' },
  ];

  return (
    <Drawer maxWidth={800} isOpen={isOpen} canOutsideClickClose={true} onClose={handleClose}>
      <DrawerHeader title="Candidate Details" handleClose={handleClose} />
      <Divider />
      <div>
        <ResponsiveTabs
          id="approval-tabs"
          defaultSelectedTabId={selectedTab}
          muted={true}
          items={tabs}
          onTabChange={handleTabChange}
        />
      </div>
      <div style={{ overflowY: 'scroll' }}>
        <Card
          paddingLevel={PaddingLevel.REGULAR}
          level={CardLevel.NONE}
          cardInnerProps={{ className: 'pad-h-5 pad-v-3' }}
        >
          {isJobSeekersLoading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <div id="details">
                <Heading2>Details</Heading2>
                <ul className={Classes.LIST_TABULAR}>
                  <li>
                    <Body className="mar-r-2">Red flagged</Body>
                    {candidate.redFlag ? 'Yes' : 'No'}
                  </li>
                  <li>
                    <Body className="mar-r-2">Red flag reason</Body>
                    {candidate.redFlagReason}
                  </li>
                  <li>
                    <Body className="mar-r-2">Eligibility overwritten</Body>
                    {lastestJobSeeker ? (lastestJobSeeker.eligibilityOverwrite ? 'Yes' : 'No') : 'No'}
                  </li>
                  <li>
                    <Body className="mar-r-2">Eligibility overwrite reason</Body>
                    {lastestJobSeeker ? lastestJobSeeker.eligibilityOverwriteReason : 'None provided'}
                  </li>
                </ul>
              </div>
              <Divider className="mar-v-3" muted={true} />
              <Heading2 id="allocations">Allocations</Heading2>
              <AllocationTable
                applications={flatten(
                  jobSeekers.map((jobSeeker) => {
                    return jobSeeker.applications.map((application) => {
                      application.programmeCycleName = jobSeeker.programmeCycleName;
                      return application;
                    });
                  }),
                )}
                fetchData={fetchData}
              />
              <Divider className="mar-v-3" muted={true} />
              <div id="applications">
                <Heading2>Applications</Heading2>
                <Frow verticalGutters={32}>
                  {jobSeekers.map((jobSeeker) => {
                    return (
                      <Col key={jobSeeker.id}>
                        <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.FILLED}>
                          <Subheading1>{jobSeeker.programmeCycleName}</Subheading1>
                          <Subheading2 className="mar-t-2">Contact details</Subheading2>
                          <ul className={Classes.LIST_TABULAR}>
                            <li>
                              <Body className="mar-r-2">First name</Body>
                              {jobSeeker.firstName}
                            </li>
                            <li>
                              <Body className="mar-r-2">Surname</Body>
                              {jobSeeker.lastName}
                            </li>
                            <li>
                              <Body className="mar-r-2">Email</Body>
                              {jobSeeker.email}
                            </li>
                            <li>
                              <Body className="mar-r-2">Phone number</Body>
                              {jobSeeker.phoneNumber}
                            </li>
                            <li>
                              <Body className="mar-r-2">Eligible</Body>
                              {jobSeeker.eligible ? 'Yes' : 'No'}
                            </li>
                            <li>
                              <Body className="mar-r-2">Submission date</Body>
                              {format(jobSeeker.createdAt, 'MMMM Do YYYY')}
                            </li>
                          </ul>
                          <Subheading2 className="mar-t-2">Personal details</Subheading2>
                          <ul className={Classes.LIST_TABULAR}>
                            <li>
                              <Body className="mar-r-2">CV Link</Body>
                              <Link href={jobSeeker.cvUrl} target="_blank">
                                Click here
                              </Link>
                            </li>
                            <li>
                              <Body className="mar-r-2">Level of education</Body>
                              {jobSeeker.levelOfEducation}
                            </li>
                            {jobSeeker.levelOfEducation === 'Gap Year' && (
                              <li>
                                <Body className="mar-r-2">Unconditional offer?</Body>
                                {jobSeeker.unconditionalOffer ? 'Yes' : 'No'}
                              </li>
                            )}
                            <li>
                              <Body className="mar-r-2">University</Body>
                              {jobSeeker.university}
                            </li>
                            <li>
                              <Body className="mar-r-2">Full name of University</Body>
                              {jobSeeker.otherUniversityName}
                            </li>
                            <li>
                              <Body className="mar-r-2">Year of graduation</Body>
                              {jobSeeker.yearOfGraduation}
                            </li>
                            {/* We get this, but it doesn't actually seem to be on the form */}
                            {/* <li>
                        <Body className="mar-r-2">Year of graduation more info</Body>
                        {jobSeeker.yearOfStudyMoreInfo}
                      </li> */}
                            <li>
                              <Body className="mar-r-2">Degree subject closest match</Body>
                              {jobSeeker.degreeSubject}
                            </li>
                            <li>
                              <Body className="mar-r-2">Full title of Degree</Body>
                              {jobSeeker.exactDegreeTitle}
                            </li>
                            <li>
                              <Body className="mar-r-2">Ethnicity</Body>
                              {jobSeeker.ethnicity}
                            </li>
                            <li>
                              <Body className="mar-r-2">Other ethnic background</Body>
                              {jobSeeker.otherEthnicBackground}
                            </li>
                            <li>
                              <Body className="mar-r-2">Visa required?</Body>
                              {jobSeeker.visaRequired}
                            </li>
                            <li>
                              <Body className="mar-r-2">Visa required reason</Body>
                              {jobSeeker.visaRequiredReason}
                            </li>
                            <li>
                              <Body className="mar-r-2">Visa not required reason</Body>
                              {jobSeeker.visaNotRequiredReason}
                            </li>
                            <li>
                              <Body className="mar-r-2">Additional visa info</Body>
                              {jobSeeker.moreVisaInfo}
                            </li>
                            <li>
                              <Body className="mar-r-2">Gender</Body>
                              {jobSeeker.gender}
                            </li>
                          </ul>
                          <Subheading2 className="mar-t-2">Application details</Subheading2>
                          <ul className={Classes.LIST_TABULAR}>
                            {jobSeeker.addresses.map((address: Address, index: number) => {
                              return (
                                <React.Fragment key={address.id}>
                                  <li>
                                    <Body className="mar-r-2">Address {index + 1}</Body>
                                    {address.address}
                                  </li>
                                  <li>
                                    <Body className="mar-r-2">Address {index + 1} region</Body>
                                    {humanize(address.region)}
                                  </li>
                                </React.Fragment>
                              );
                            })}
                            <li>
                              <Body className="mar-r-2">Preference one</Body>
                              {jobSeeker.internshipSectorPreferenceOne}
                            </li>
                            <li>
                              <Body className="mar-r-2">Preference one description</Body>
                              {jobSeeker.internshipSectorPreferenceOneRationale}
                            </li>
                            <li>
                              <Body className="mar-r-2">Preference two</Body>
                              {jobSeeker.internshipSectorPreferenceTwo}
                            </li>
                            <li>
                              <Body className="mar-r-2">Preference two description</Body>
                              {jobSeeker.internshipSectorPreferenceTwoRationale}
                            </li>
                            <li>
                              <Body className="mar-r-2">Preference three</Body>
                              {jobSeeker.internshipSectorPreferenceThree}
                            </li>
                            <li>
                              <Body className="mar-r-2">Preference three description</Body>
                              {jobSeeker.internshipSectorPreferenceThreeRationale}
                            </li>
                            <li>
                              <Body className="mar-r-2">Proficient skills</Body>
                              {jobSeeker.proficientSkills}
                            </li>
                            <li>
                              <Body className="mar-r-2">Source</Body>
                              {jobSeeker.discoverySource}
                            </li>
                            {jobSeeker.discoverySource === 'Other' && (
                              <li>
                                <Body className="mar-r-2">Source more info</Body>
                                {jobSeeker.discoverySourceMoreInfo}
                              </li>
                            )}
                          </ul>
                          <Subheading2 className="mar-t-2">Diversity & Inclusion</Subheading2>
                          <ul className={Classes.LIST_TABULAR}>
                            <li>
                              <Body className="mar-r-2">Disability statements</Body>
                              {jobSeeker.disabilityStatements}
                            </li>
                            <li>
                              <Body className="mar-r-2">Disability conditions</Body>
                              {jobSeeker.disabilityConditions}
                            </li>
                            <li>
                              <Body className="mar-r-2">Additional conditional info</Body>
                              {jobSeeker.furtherConditionInformation}
                            </li>
                            <li>
                              <Body className="mar-r-2">Reasonable adjustments</Body>
                              {jobSeeker.reasonableAdjustments}
                            </li>
                            <li>
                              <Body className="mar-r-2">Reasonable adjustments more information</Body>
                              {jobSeeker.reasonableAdjustmentsInformation}
                            </li>
                            <li>
                              <Body className="mar-r-2">Social barriers</Body>
                              {jobSeeker.socialBarriers}
                            </li>
                            <li>
                              <Body className="mar-r-2">Postcode at 14</Body>
                              {jobSeeker.postcodeAt14}
                            </li>
                            <li>
                              <Body className="mar-r-2">Refugee of asylum status</Body>
                              {jobSeeker.refugeeOrAsylumStatus ? 'Yes' : 'No'}
                            </li>
                            <li>
                              <Body className="mar-r-2">Spent time in care or are carer</Body>
                              {jobSeeker.spentTimeInCareOrCarer ? 'Yes' : 'No'}
                            </li>
                            <li>
                              <Body className="mar-r-2">Secondary school type</Body>
                              {jobSeeker.secondarySchoolType}
                            </li>
                            <li>
                              <Body className="mar-r-2">Financial support</Body>
                              {jobSeeker.financialSupport ? 'Yes' : 'No'}
                            </li>
                            <li>
                              <Body className="mar-r-2">Highest qualification of parent</Body>
                              {jobSeeker.highestQualificationOfParent}
                            </li>
                            <li>
                              <Body className="mar-r-2">Eligible for free school meals</Body>
                              {jobSeeker.eligibleForSchoolMeals}
                            </li>
                            <li>
                              <Body className="mar-r-2">Parent occupation</Body>
                              {jobSeeker.parentOccupation}
                            </li>
                            <li>
                              <Body className="mar-r-2">Household income</Body>
                              {jobSeeker.householdIncome}
                            </li>
                          </ul>
                        </Card>
                      </Col>
                    );
                  })}
                </Frow>
              </div>
            </div>
          )}
        </Card>
      </div>
    </Drawer>
  );
}
