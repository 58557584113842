import { Alerter, IconButton40, IconNames16, Intent, Menu, MenuItem, Popover } from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { Application } from '../../../../javascript/models';
import { createToast } from '../../../FlashToaster';
import PaginationComponent from '../../../shared/table/PaginationComponent';
import humanize from '../../../shared/utilities/humanize';

export function AllocationTable({ applications, fetchData }) {
  const columns = [
    {
      Cell: ({ original }: { original: Application }) => {
        return <div>{original.job.title}</div>;
      },
      Header: 'Internship title',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Application }) => {
        return <div>{original.job.company ? original.job.company.name : 'No company'}</div>;
      },
      Header: 'Company',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Application }) => {
        return <div>{humanize(original.status)}</div>;
      },
      Header: 'Status',
      sortable: false,
      width: 120,
    },
    {
      Cell: ({ original }: { original: Application }) => {
        return <div>{original.programmeCycleName}</div>;
      },
      Header: 'Cycle',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Application }) => {
        const handleDelete = () => {
          Alerter.create({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, delete',
            intent: Intent.DANGER,
            onConfirm: () => {
              original.destroy().then((success: boolean) => {
                if (success) {
                  createToast({ type: 'success', text: 'Application deleted' });
                  fetchData();
                }
              });
            },
            text:
              'Are you sure you want to delete this application? This will remove the application from the organisations Pinpoint instance',
          });
        };

        return (
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem intent={Intent.DANGER} text="Delete" onClick={handleDelete} />
              </Menu>
            </Popover>
          </div>
        );
      },
      width: 80,
    },
  ];

  const paginationProps = React.useCallback(() => {
    return {
      meta: {
        total: applications.length,
      },
      pageNumber: 0,
    };
  }, [applications]);

  return (
    <ReactTable
      columns={columns}
      data={applications}
      PaginationComponent={PaginationComponent}
      getPaginationProps={paginationProps}
      className="-unthemed"
      minRows={1}
    />
  );
}
