import { Body, Button, Dialog, FormGroup, TextArea } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { Candidate } from '../../../../javascript/models';

export function RedFlagDialog({
  candidate,
  handleClose,
  isOpen,
  fetchData,
}: {
  handleClose: () => void;
  isOpen: boolean;
  fetchData: () => void;
  candidate: Candidate;
}) {
  const [redFlagReason, setRedFlagReason] = React.useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleRedFlagReasonChange = (event) => setRedFlagReason(event.target.value);

  const handleConfirm = () => {
    setIsSubmitting(true);
    candidate.redFlag = true;
    candidate.redFlagReason = redFlagReason;
    candidate.save().then((success) => {
      if (success) {
        handleClose();
        fetchData();
      } else {
        setIsSubmitting(false);
      }
    });
  };

  return (
    <Dialog isOpen={isOpen} title="Red flag" onClose={handleClose}>
      <Body className="mar-b-1">Please provide the reason for red flagging this candidate</Body>
      <FormGroup label="Reason">
        <TextArea onChange={handleRedFlagReasonChange} />
      </FormGroup>
      <Button text="Confirm" onClick={handleConfirm} fill={true} disabled={!redFlagReason} loading={isSubmitting} />
    </Dialog>
  );
}
