import { Alerter, Button, Card, CardLevel, Drawer, Frow, Heading2, Intent, PaddingLevel } from '@pinpointhq/thumbtack';
import { sortBy } from 'lodash';
import * as React from 'react';
import ReactTable from 'react-table';
import { Contact } from '../../../../../javascript/models';
import { createToast } from '../../../../FlashToaster';
import { DrawerHeader } from '../../../../shared/DrawerHeader';
import PaginationComponent from '../../../candidates/awaiting/PaginationComponent';
import { Form } from './contacts/Form';
import { columns } from './contacts/columns';

export function Contacts({ company, isLoading, fetchData }) {
  const [editingContact, setEditingContact] = React.useState<Contact>();

  const handleEdit = (contact: Contact) => {
    setEditingContact(contact);
  };

  const handleDelete = (contact) => {
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, delete',
      intent: Intent.DANGER,
      onConfirm: () => {
        contact.destroy().then((success) => {
          if (success) {
            createToast({ type: 'success', text: 'Successfully deleted candidate' });
            fetchData();
            setEditingContact(null);
          } else {
            createToast({ type: 'error', text: 'Unable to delete candidate' });
          }
        });
      },
      text: 'Are you sure you want to delete this contact? This can not be undone',
    });
  };

  const handleUpdateActive = (contact) => {
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      intent: Intent.NONE,
      onConfirm: () => {
        contact.status = contact.status === 'active' ? 'inactive' : 'active';
        contact.save().then((success) => {
          createToast({ type: 'success', text: 'Successfully updated contact' });
          fetchData();
        });
      },
      text: `Are you sure you want to mark this contact as ${contact.status === 'active' ? 'inactive' : 'active'}?`,
    });
  };

  const handleClose = () => {
    setEditingContact(null);
  };

  const handleNewContact = () => setEditingContact(new Contact({ companyId: company.id, programmeIds: [] }));

  return (
    <>
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.NONE}>
        <Frow gutters={16} verticalGutters={16} justifyContent="space-between" alignItems="center">
          <div>
            <Heading2>Contacts</Heading2>
          </div>
          <div>
            <Button text="Add Contact" onClick={handleNewContact} />
          </div>
        </Frow>
      </Card>
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.NONE}>
        <ReactTable
          columns={columns(handleEdit, handleDelete, handleUpdateActive, fetchData)}
          data={company ? sortBy(company.contacts, ['status']) : []}
          PaginationComponent={PaginationComponent}
          loading={isLoading}
          className="-unthemed"
          minRows={1}
        />
      </Card>
      {!!editingContact && (
        <Drawer isOpen={!!editingContact} onClose={handleClose} maxWidth={800}>
          <DrawerHeader title={`${editingContact.isPersisted ? 'Edit' : 'Create'} Contact`} handleClose={handleClose} />
          <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.NONE}>
            <Form {...{ company, editingContact, fetchData, setEditingContact }} />
          </Card>
        </Drawer>
      )}
    </>
  );
}
